import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalLimit',
})
export class DecimalLimitPipe implements PipeTransform {
  transform(value: number, limit: number): number {
    const multiplier = Math.pow(10, limit);
    return Math.floor(value * multiplier) / multiplier;
  }
}
