import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConnectionsService {

  constructor(private http: HttpClient) { }

  private baseUrl: string = 'https://webapi.keephashing.it/API/'; //change
  private statsUrl: string = 'https://api.alephium.keephashing.it/stats'; //change

  fetchWalletData(wallet: string, type: number) {
		const body = {
			wallet,
      type
		}
		return this.http.post<{ok:boolean,data1:{data:Array<any>,labels:Array<any>,unit:any},data2:{data:Array<any>,labels:Array<any>,unit:any},data3:{data:Array<any>,labels:Array<any>,unit:any}}>(this.baseUrl+'wallet', body); // <- maybe use array of json objects line [{timestap:22222,data:281994},{timestap:234234,data:32423}]
	}
  fetchTransactionsData() {
		const body = {
		}
		return this.http.post<{ok:boolean,data:Array<any>}>(this.baseUrl+'transactions', body); // <- maybe use array of json objects line [{timestap:22222,data:281994},{timestap:234234,data:32423}]
	}
  fetchBlocksData() {
		const body = {
		}
		return this.http.post<{ok:boolean,data:Array<any>}>(this.baseUrl+'blocks', body); // <- maybe use array of json objects line [{timestap:22222,data:281994},{timestap:234234,data:32423}]
	}
  fetchStatsData() {
		const body = {
		}
		return this.http.post<{ok:boolean,data:Array<any>}>(this.baseUrl+'stats', body); // <- maybe use array of json objects line [{timestap:22222,data:281994},{timestap:234234,data:32423}]
	}
  fetchPoolStatsApi() {
		const body = {
		}
		return this.http.get<{ ok: boolean; data: any }>(this.statsUrl);
	}
  fetchWalletBalance(wallet: string, type: number) {
		const body = {
			wallet,
      type
		}
		return this.http.post<{ok:boolean, data:Array<any>}>(this.baseUrl+'balance', body);
	}

}
