import { Component, HostListener, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'chart-line',
    templateUrl: './main.html',
    styleUrls: ['./main.scss']
})
export class main implements OnInit {
  shorcut:any;
  id:any;
  items: MenuItem[] | undefined;
  activeItem: MenuItem | undefined;
  constructor(){
    this.getScreenSize();
    var url = new URL(window.location.href);
    this.id = url.searchParams.get("id");
  }
  onActiveItemChange(event: MenuItem) {
    this.activeItem = event;
  }

  screenWidth!: number;

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
      this.screenWidth = window.innerWidth;
      console.log("resized");

  }

  ngOnInit() {
    const documentStyle = getComputedStyle(document.documentElement);
    this.items = [
      { label: 'Dashboard', icon: 'pi pi-fw pi-home' },
      { label: 'Blocks', icon: 'pi pi-fw pi-table' },
      { label: 'Transactions', icon: 'pi pi-fw pi-wallet' },
      { label: 'Connect', icon: 'pi pi-fw pi-bolt' }
    ];
    if(this.id){
      this.shorcut=this.id
    }
    this.activeItem = this.items[0];

  }

  receiver(receivedFromChild:any){

    this.shorcut=receivedFromChild;
    if(this.activeItem!=this.items[0]){
      this.activeItem=this.items[0];
    }
  }


}
