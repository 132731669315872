<div [ngClass]="screenWidth < 500 ? 'padd card':'card'">
    <!-- connection-page.component.html -->
<div class="connection-card">
    <h2>Thanks for choosing us!</h2>
    <div class="card-content">
      <p>Use the following host to connect your miner to our pool:</p>
      <div class="input-copy-container">
        <input
          id="pool-url"
          type="text"
          class="copy-input"
          value="stratum+tcp://alephium.keephashing.it:12345"
          readonly
          (click)="copyToClipboard('pool-url')"
        />
        <button class="copy-button" (click)="copyToClipboard('pool-url')">
          <span *ngIf="!copySuccess" class="inline-flex items-center">
            <i class="pi pi-copy" aria-hidden="true"></i>
            <span class="text-xs font-semibold">Copy</span>
          </span>
          <span *ngIf="copySuccess" class="inline-flex items-center">
            <i class="pi pi-check" aria-hidden="true"></i>
            <span class="text-xs font-semibold">Copied</span>
          </span>
        </button>
      </div>
      <p></p>
      <div class="links">
        <p>
          Not sure how to start mining? Check out our
          <a href="https://keephashing.it/beginners-guide" target="_blank" class="link">beginners guide</a>.
        </p>
        <p>
          Need Assistance? Visit our
          <a href="https://keephashing.it/contact" target="_blank" class="link">contact page</a>.
        </p>
      </div>
    </div>
  </div>
  
</div>