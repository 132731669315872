import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-graphic-line',
  templateUrl: './graphic-line.component.html',
  styleUrls: ['./graphic-line.component.scss']
})
export class GraphicLineComponent {
  @Input() data:any;
  options:any;
  enddata:any;
  enddataunit:string;
  ngOnInit() {
    const message=[{ severity: 'info', summary: 'Info', detail: 'No se han encontrado resultados.' }];
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    this.enddata={
      labels: this.data.labels,
          datasets: [
              {
                  label: 'Hashrate',
                  data: this.data.data,
                  fill: true,
                  // textColor: documentStyle.getPropertyValue('--blue-500'),
                  // borderColor: '#4083f3',
                  tension: 0.3,
                  // color: '#4083f3'
                  // fillColor : "#4083f3",
                  // backgroundColor: '#4083f3'
              }
          ]
    }
    //console.log(this.data.unit);
    this.enddataunit=this.data.unit;
    this.options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
              display:false
                // labels: {
                //     // color: textColor
                // }
            }
        },
        elements:{
          point: {
            borderColor: ' #FFFFFF',
            backgroundColor: '#FFFFFF',
            hitRadius: 5
          },
          line: {
            borderColor: documentStyle.getPropertyValue('--blue-500'),
            backgroundColor: 'rgba(64, 131, 243,1)'
          },
          // label: {
          //   borderColor: ' #4083f3',
          //   backgroundColor: '#4083f3',
          //   textColor: '#4083f3'
          // }
        },
        scales: {
            x: {
                ticks: {
                    //color: textColorSecondary
                    //color: 'rgba(255, 255, 255,1)'
                    // fontColor: '#4083f3',
                    color: '#FFFFFF'
                },
                grid: {
                    color: false,
                    drawBorder: false
                }
            },
            y: {
                min: 0,
                ticks: {
                  // fontColor: '#4083f3',
                    //color: textColorSecondary
                    color: '#FFFFFF'
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }
        }
    };

  }
}
