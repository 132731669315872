import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ConnectionsService } from '../connections.service';
import { Router } from '@angular/router';
// import { Router } from '@angular/router';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent {
  constructor(private backend:ConnectionsService,private router: Router){
    // this.getScreenSize();
  }
  @Output() sender = new EventEmitter();
  google="google"
  data: any;
  datarows:  any[] | undefined | any;
  headers=['Worker','Tx ID','Time']; // Amount,
  search=false;
  loaded=false;
  isData:any;
  messages1=[{ severity: 'info', summary: 'Info', detail: 'No se han encontrado resultados.' }];
  async ngOnInit() {
    this.getScreenSize()
    this.datarows = Array.from({ length: 10 }).map((_, i) => `Item #${i}`);
    await this.fetch();
  }
  goTo(wallet:any){
    // console.log("click");
    this.router.navigate(['./'],{ queryParams: { id: wallet} })
    this.sender.emit(wallet);
    // TODO change to route with url param
  }
  async fetch() {
    this.loaded=false;
    try {
      this.datarows=await firstValueFrom(this.backend.fetchTransactionsData());
      this.datarows=this.datarows.data;
    } catch (error) {
      this.datarows=null
    }
    // console.log(this.datarows);

    this.loaded=true;

  }
  screenWidth!: number;

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
      this.screenWidth = window.innerWidth;
      console.log("resized");

  }
}
