<div [ngClass]="screenWidth < 500 ? 'padd card':'card'">
  <p-table [value]="datarows" responsiveLayout="scroll">
      <ng-template pTemplate="header">
          <tr>
              <th *ngFor="let h of headers">{{h}}</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-transaction>
          <tr *ngIf="transaction.worker && transaction.txid && transaction.timestamp; else loading ">
              <td *ngIf="screenWidth > 500;else elseblock" class="pointer" (click)="goTo(transaction.worker)">{{transaction.worker}}</td>
              <ng-template #elseblock>
                <td class="pointer" (click)="goTo(transaction.worker)">...{{transaction.worker | slice:(transaction.worker.length - 8):transaction.worker.length}}</td>
              </ng-template>
              <td *ngIf="screenWidth > 500;else elseblock2"><a [attr.href]="'https://explorer.alephium.org/transactions/'+transaction.txid" target="_blank">{{transaction.txid}}</a></td>
              <ng-template #elseblock2>
                <td><a [attr.href]="'https://explorer.alephium.org/transactions/'+transaction.txid" target="_blank">{{transaction.txid | slice:0:8}}...</a></td>
              </ng-template>
              <!-- <td>{{transaction.amount}}</td> -->
              <td>{{transaction.timestamp * 1000 | date : 'dd/MM/yyy - hh:mm'}}</td>
            </tr>
            <ng-template #loading>
              <tr *ngIf="!loaded">
                <td *ngFor="let i of headers">
                  <p-skeleton></p-skeleton>
                </td>
            </tr>
          </ng-template>
        </ng-template>
      </p-table>
      <p-messages *ngIf="loaded && !datarows" [(value)]="messages1" [enableService]="false" [closable]="false" ></p-messages>
</div>
