import { Component, HostListener } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';



@Component({
  selector: 'app-connection-page',
  templateUrl: './connection-page.component.html',
  styleUrls: ['./connection-page.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ConnectionPageComponent {
  screenWidth!: number;

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
      this.screenWidth = window.innerWidth;
      console.log("resized");

  }

  copySuccess: boolean = false;

  copyToClipboard(elementId: string) {
    const inputElement = document.getElementById(elementId) as HTMLInputElement;
    if (inputElement) {
      inputElement.select();
      document.execCommand('copy');
      inputElement.setSelectionRange(0, 0); 

      this.showSuccessMessage();
    }
  }

  showSuccessMessage() {
    this.copySuccess = true; 
    setTimeout(() => this.copySuccess = false, 2000);
  }
}