import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { ConnectionsService } from '../connections.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent {
  @Input() walletShorcut:any;
  @Output() sender = new EventEmitter();
  items: MenuItem[] | undefined;
  lastSearch:any;
  activeItem: MenuItem | undefined;
  stateOptions: any[] = [{label: '6 Hours', value: '6H'}, {label: '1 Day', value: '1H'}, {label: '30 Days', value: '10min'}];
  stateOptions2: any[] = [{label: '1 Day', value: '1H'}, {label: '30 Days', value: '10min'}];
  data:any;
  search=false;
  loaded=false;
  search_value:any;
  messages1=[{ severity: 'info', summary: 'Info', detail: 'Wallet not found.' }];
  messages2=[{ severity: 'info', summary: 'Info', detail: 'Wrong format, wallet must only contain letters and numbers, no special characters allowed.' }];
  messages3=[{ severity: 'info', summary: 'Info', detail: 'Unable to fetch data.' }];
  screenWidth!: number;
  value: string = '6H';
  value2: string = '1H';
  data1: any =false;
  wformat: boolean =false;

  poolStats: { title: string; value: string; unit: string }[] = [];
  minerStats: { title: string; value: string; unit: string }[] = [];

  constructor(private backend:ConnectionsService,private router: Router,private datePipe: DatePipe){
    this.getScreenSize();
  }

  ngOnInit() {
    if(this.walletShorcut){
      this.search_value=this.walletShorcut;
      this.walletShorcut=null;
      this.clickPress();
    }else{
      this.clickPress();
    }
  }
  async clickPress(type?:number) {
    var regx=/^[a-z0-9]+$/i;
    this.wformat=false;
    // console.log(this.search_value.length);
    // Put here the stats query
    try {
      const response = await firstValueFrom(this.backend.fetchPoolStatsApi());
      this.poolStats = this.transformApiResponseToJSON(response);
      this.poolStats = this.convertHashRates(this.poolStats)
      //console.log('Transformed Pool Stats:', this.poolStats);
    } catch (error) {
      console.error('Error fetching pool stats:', error);
    }
    try {
      var dataAux2:any;
      dataAux2=await firstValueFrom(this.backend.fetchStatsData())
      dataAux2=dataAux2.data
      dataAux2.data1.labels.forEach((lab,index) =>{dataAux2.data1.labels[index]=this.formatDateHours(lab)})
      dataAux2.data2.labels.forEach((lab,index) =>{dataAux2.data2.labels[index]=this.formatDate(lab)})
      this.data1=dataAux2

    } catch (error) {
      this.data1=null
    }
    // console.log(this.data1);

    if(type==1 && this.lastSearch){
      this.search_value=this.lastSearch;
    }
    if(!this.search_value){return}
    console.log(this.search_value.length);
    console.log(regx.test(this.search_value));

    //if(this.search_value.length==45 && regx.test(this.search_value)){
    if(regx.test(this.search_value)){
      this.search=true;
      this.loaded=false;
      console.log(this.search_value,'condicional');
      this.router.navigate(['./'],{ queryParams: { id: this.search_value} })
      try {
        this.defWallet();
        this.lastSearch=this.search_value
        var dataAUX=await firstValueFrom(this.backend.fetchWalletData(this.search_value,1));
        // console.log(dataAUX);
        dataAUX.data1.labels.forEach((lab,index) =>{dataAUX.data1.labels[index]=this.formatDateHours(lab)})
        dataAUX.data2.labels.forEach((lab,index) =>{dataAUX.data2.labels[index]=this.formatDateHours(lab)})
        dataAUX.data3.labels.forEach((lab,index) =>{dataAUX.data3.labels[index]=this.formatDate(lab)})
        // console.log(dataAUX);

        this.data=dataAUX

        try {
          var balance:any;
          balance=await firstValueFrom(this.backend.fetchWalletBalance(this.search_value,1))
          balance=balance.balance
          console.log(balance)
          let lastHashrate = dataAUX.data1.data[dataAUX.data1.data.length - 1];
          let minerStats = [
          { title: 'Unpaid Balance', value: balance, unit: 'ALPH' },
          { title: 'Current Hashrate', value: lastHashrate, unit: 'H/s' },
           ];
          this.minerStats = this.convertHashRates(minerStats);
    
        } catch (error) {
          balance=0
        }

      } catch (error) {
        this.data=null
      }

      this.loaded=true;

    }else{
      this.loaded=false;
      this.search=false;
      this.wformat=true;
      // console.log("else click press");
      //  TODO message invalid format

    }

  }
  transformApiResponseToJSON(response: any): { title: string; value: string; unit: string }[] {
    return [
      { title: 'Pool Hashrate', value: response.pool_hashrate, unit: 'H/s' },
      { title: 'Network Hashrate', value: response.network_hashrate, unit: 'H/s' },
      { title: 'Payment Interval', value: parseInt((parseFloat(response.payment_interval) / 3600).toFixed(2)).toString(), unit: 'hours' },
      { title: 'Active Miners', value: parseInt(response.active_miners).toString(), unit: 'miners' },
      { title: 'Network Difficulty', value: response.network_difficulty, unit: '' },
      { title: 'Minimum Payout', value: response.minimum_payout, unit: 'ALPH' }
    ];
  }

  convertHashRates(poolStats: { title: string; value: string; unit: string }[]): { title: string; value: string; unit: string }[] {
    const hashRateUnits = ['MH/s', 'GH/s', 'TH/s', 'PH/s', 'EH/s'];
    const difficultyUnits = ['', 'K', 'M', 'G', 'T', 'P', 'E'];

    return poolStats.map((entry) => {
      let value = parseFloat(entry.value);
      let unitIndex = 0;

      if (entry.title === 'Pool Hashrate' || entry.title === 'Network Hashrate' || entry.title === 'Current Hashrate') {
        while (value >= 1000 && unitIndex < hashRateUnits.length - 1) {
          value /= 1000;
          unitIndex++;
        }

        return {
          title: entry.title,
          value: value.toFixed(2), 
          unit: hashRateUnits[unitIndex]
        };
      } else if (entry.title === 'Network Difficulty') {
        while (value >= 1000 && unitIndex < difficultyUnits.length - 1) {
          value /= 1000;
          unitIndex++;
        }

        return {
          title: entry.title,
          value: value.toFixed(2), 
          unit: difficultyUnits[unitIndex]
        };
      }

      return entry;
    });
  }
  copyCurrentUrl(): void {
    const currentUrl = window.location.href; // Get the current URL
    navigator.clipboard.writeText(currentUrl).then(() => {
      console.log('URL copied to clipboard:', currentUrl);
      alert('URL copied to clipboard! This is a shortcut to your wallet stats.');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
      this.screenWidth = window.innerWidth;
      console.log("resized");

  }
  defWallet(){
    // console.log("emmiting:",this.search_value)
    this.sender.emit(this.search_value);
  }
  formatDateHours(timestamp: number): string {
    timestamp=timestamp*1000
    // console.log('pre-Result:',timestamp);

    var result= this.datePipe.transform(timestamp, 'HH:mm')!;
    // console.log('result=',result);
    return result

  }
  formatDate(timestamp: number): string {
    // need to adjust if it's in seconds.
    // timestamp=timestamp*1000
    return this.datePipe.transform(timestamp, 'yyyy-MM-dd')!;
  }
}