<!-- Searchbar -->
<div class="flex flex-wrap justify-content-center gap-3 mb-3">
  <span id="searchbar" class="p-input-icon-right " style="width: 80%;">
    <i class="pi pi-search"></i>
    <input type="text" placeholder="Your wallet address" (keydown.enter)="clickPress()" pInputText [(ngModel)]="search_value" style="width: 100%;" />
  </span>
</div>
<!-- Principal block -->
<div *ngIf="loaded==true && (data);else noData">
  <div class="stats-title">
    <span style="color:#4083f3; font-weight: 700;">MINER</span>
    <span> STATS</span>
  </div>
  <div class="data-panel-miner">
    <div class="data-row" *ngFor="let entry of minerStats; let i = index">
      <div class="data-value">{{ entry.value }} <span class="data-unit">{{ entry.unit }}</span></div>
      <div class="data-title">{{ entry.title }}</div>
    </div>
  </div>
  
  
  <div class="button-container">
    <span class="sidebuttons flex m-1 p-0">
      <p-button icon="pi pi-copy" label="Copy miner URL" (onClick)="copyCurrentUrl()"></p-button>
    </span>
    <span class="sidebuttons flex m-1 p-0" style="align-self: left !important;">
      <p-button icon="pi pi-refresh" label="Refresh data" (onClick)="clickPress(1)"></p-button>
    </span>
  </div>
  <div class="card flex justify-content-center m-1 p-0">
    <p-selectButton [options]="stateOptions" [(ngModel)]="value" optionLabel="label" optionValue="value"></p-selectButton>
  </div>
  <span [ngSwitch]="value">
    <span *ngSwitchCase="stateOptions[0].value">
      <app-graphic-line [data]="data.data1"></app-graphic-line>
    </span>
    <span *ngSwitchCase="stateOptions[1].value">
      <app-graphic-line [data]="data.data2"></app-graphic-line>
    </span>
    <span *ngSwitchCase="stateOptions[2].value">
      <app-graphic-line [data]="data.data3"></app-graphic-line>
    </span>
  </span>
</div>
<ng-template #noData >
  <p-messages *ngIf="loaded==true" [(value)]="messages1" [enableService]="false" [closable]="false" ></p-messages>
  <p-messages *ngIf="wformat==true" [(value)]="messages2" [enableService]="false" [closable]="false" ></p-messages>
  <p-skeleton *ngIf="loaded==false && search==true" width="70vw" height="40vw"></p-skeleton>
</ng-template>
<div *ngIf="(data1);else noData">
<div class="stats-title" >
  <span style="color:#4083f3; font-weight: 700;">POOL</span>
  <span> STATS</span>
</div>
<div class="data-panel">
  <div class="data-row" *ngFor="let entry of poolStats; let i = index">
    <div class="data-value">{{ entry.value }} <span class="data-unit">{{ entry.unit }}</span></div>
    <div class="data-title">{{ entry.title }}</div>
  </div>
</div>

<div class="card flex justify-content-center m-1 p-0">
  <p-selectButton [options]="stateOptions2" [(ngModel)]="value2" optionLabel="label" optionValue="value"></p-selectButton>
</div>
<span [ngSwitch]="value2">
  <span *ngSwitchCase="stateOptions2[0].value">    
    <app-graphic-line [data]="data1.data1"></app-graphic-line>
  </span>
  <span *ngSwitchCase="stateOptions2[1].value">
    <app-graphic-line [data]="data1.data2"></app-graphic-line>
  </span>
</span>
</div>
<ng-template #noData2 >
  <p-messages *ngIf="loaded==true" [(value)]="messages3" [enableService]="false" [closable]="false" ></p-messages>
  <p-skeleton *ngIf="loaded==false && search==true" width="70vw" height="40vw"></p-skeleton>
</ng-template>
