import { Component, HostListener } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ConnectionsService } from '../connections.service';

@Component({
  selector: 'app-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.scss']
})
export class BlocksComponent {
  constructor(private backend:ConnectionsService){
    // this.getScreenSize();
  }
  data: any;
  datarows:  any[] | undefined | any;
  headers=['Block Hash','Chain Index','Reward','Time'];
  search=false;
  loaded=false;
  isData:any;
  messages1=[{ severity: 'info', summary: 'Info', detail: 'No se han encontrado resultados.' }];
  async ngOnInit() {
    this.getScreenSize()
    this.datarows = Array.from({ length: 10 }).map((_, i) => `Item #${i}`);
    await this.fetch()
  }
  async fetch() {
    this.loaded=false;
    try {
      this.datarows=await firstValueFrom(this.backend.fetchBlocksData());
      this.datarows=this.datarows.data;
    } catch (error) {
      this.datarows=null
    }
    // console.log(this.datarows);

    this.loaded=true;

  }
  screenWidth!: number;

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
      this.screenWidth = window.innerWidth;
      console.log("resized");

  }
}
