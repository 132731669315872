<div [ngClass]="screenWidth < 500 ? 'padd card':'card'">
  <p-table [value]="datarows" responsiveLayout="scroll" [paginator]="true" [rows]="10">
      <ng-template pTemplate="header">
          <tr>
              <th *ngFor="let h of headers">{{h}}</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-block>
          <tr *ngIf="block.block_hash && block.chain && block.reward && block.timestamp;else loading">
              <td *ngIf="screenWidth > 500;else elseblock"><a [attr.href]="'https://explorer.alephium.org/blocks/'+block.block_hash" target="_blank">{{block.block_hash}}</a></td>
              <ng-template #elseblock>
                <td><a [attr.href]="'https://explorer.alephium.org/blocks/'+block.block_hash" target="_blank">...{{block.block_hash | slice:(block.block_hash.length - 8):block.block_hash.length}}</a></td>
              </ng-template>
              <td>{{block.chain}}</td>
              <td>{{block.reward * 0.000000000000000001 | decimalLimit: 3 }}</td>
              <td>{{block.timestamp * 1000 | date : 'dd/MM/yyy - hh:mm'}}</td>
            </tr>
            <ng-template #loading>
              <tr *ngIf="!loaded">
                <td *ngFor="let i of headers">
                  <p-skeleton></p-skeleton>
                </td>
            </tr>
          </ng-template>
      </ng-template>
  </p-table>
  <p-messages *ngIf="loaded && !datarows" [(value)]="messages1" [enableService]="false" [closable]="false" ></p-messages>
</div>
