<header>
  <div class="header-content">
    <img src="assets/logo-header.png" alt="Website Logo" class="logo">
    <span class="website-name">KEEPHASHING.IT</span>
    <img src="assets/alephium-hor.png" class="logo" style=" margin-left: auto; padding-bottom: 1vh; ">
  </div>
  <!-- <div class="dashboard-logo">
    <img src="assets/alephium-hor.png">
  </div> -->
  <div class="dashboard-name">
    <span style="color:#ffffff; font-weight: 500">Pool </span>
    <span style="color:#4083f3; font-weight: 700">Dashboard</span>
  </div>
</header>
<div id="main" [ngClass]="screenWidth < 800 ? 'mobile card overflow-auto':'desktop card p-1'">
  <p-tabMenu class="sticky" [model]="items" [activeItem]="activeItem" (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>
  <div  [ngSwitch]="activeItem" style="overflow: auto;">
    <app-search-page (sender)="receiver($event)" [walletShorcut]="shorcut" *ngSwitchCase="items[0]"></app-search-page>
    <app-blocks *ngSwitchCase="items[1]"></app-blocks>
    <app-transactions (sender)="receiver($event)" *ngSwitchCase="items[2]"></app-transactions>
    <app-connection-page *ngSwitchCase="items[3]"></app-connection-page>
  </div>
</div>
